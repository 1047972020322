.index-block{

  h2{
    font-weight: 400;
    font-size: 29px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
  }
}

.index-menu{
  width: 50%;
  margin: 100px auto 0;
  display: flex;
  gap: 55px;

  button{flex: 1}
}