#ForgotPage {
  width: 350px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  
  align-items: center;
  
  > p{color: #fff; margin-bottom: 26px;}

  .login-form{
    width: 100%;
    text-align: center;

    .forgot-row{
      margin-bottom: 23px;
      text-align: right;

      .forgot-link{color: #fff; font-size: 14px;}
    }

    .buttons-row{
      display: flex;
      align-items: center;

      .site-btn{
        flex: 1 0 auto;

        &:first-child{margin-right: 13px;}
      }
    }
  }
}