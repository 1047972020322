.block-list{
  display: flex;
  gap: 35px;
  padding: 0 40px;

  height: calc(100% - 170px);

  h2{
    font-weight: 400;
    font-size: 29px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
  }

  .block{flex: 1; display: flex; flex-direction: column; height: 100%}

  .list{
    flex: 1;

    
  }
}

