main{
  height:calc(100%);
  display: flex;
 
	.right-side{
		flex: 1;

		h1{
			font-weight: 400;
			font-size: 29px;
			line-height: 34px;
			text-align: center;
			color: #FFFFFF;
			margin-top: 50px;
			margin-bottom: 90px;
		}
	}
}