#LoginPage {
  width: 350px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  
  .logo{margin-bottom: 80px;}
  .logo img{width: 165px;}
  .logo p{font-size: 29px; line-height: 34px; text-align: center; color: #FFFFFF; margin-top: 20px;}

  .login-form{
    width: 100%;

    .forgot-row{
      margin-bottom: 23px;
      text-align: right;

      .forgot-link{color: #fff; font-size: 14px;}
    }

    .buttons-row{
      display: flex;
      align-items: center;

      .site-btn{
        flex: 1 0 auto;

        &:first-child{margin-right: 13px;}
      }
    }
  }
}

.policy-link{color:#fff; margin-top: auto;}