*, *:before, *:after{box-sizing: border-box;}
html{height: 100%;}
body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: linear-gradient(179.8deg, #0087DB 10.67%, #0776BB 89.17%);
}
#root{height: 100%;}
p{padding: 0; margin: 0;}


.form-errors{
  margin-top: 23px;
  padding: 23px 27px;
  background: #cc6565;
  border-radius: 5px;

  p{}
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}