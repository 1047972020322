header {
  display: flex;
  display: flex;

  &.back{
    height: 85px;
    background: #074E7A;
    display: flex;
  
    
  }

  .left-part{height: 100%; min-height: 85px; width: 440px; display: flex; align-items:center; padding-left: 40px;}

    .back-btn{
      color: #fff;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
  
      &::before{
        content: "";
        background: url(../../assets/images/arr-left.svg) no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 25px;
      }
    }

  .header-right{
    display: flex;
    margin-left: auto;
    align-items: center;
  }
  .user-inner{
    .text{
      font-size: 20px;
      line-height: 23px;
      text-align: right;  
      color: #FFFFFF;
      margin-right: 20px;
    }

    .text.title{font-size: 29px; line-height: 34px}
  }

  .header-btns{
    display: flex;

    .btn{
      width: 120px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #074E7A;

      &:first-child{margin-right: 6px; border-radius: 0 0 0 5px}
    }
  }
}