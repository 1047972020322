.hospitals-list{
  width: 1180px;
  margin: 0 auto
}

.hospital-add-form{

  .field-block{
    background: #67B3E2;
    border-radius: 5px;
    padding: 40px;

    &:not(:last-child){margin-bottom: 28px;}

    label{font-size: 20px; line-height: 23px; color: #FFFFFF; margin-bottom: 28px; display: inline-block}
  }
}