.site-btn{
  position: relative;
  background: #074E7A;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: 64px;
  color:#fff;
  font-family: Roboto;
  border: none;
  font-size: 16px;
  padding: 0 20px;

  &.yellow {
    background: linear-gradient(180deg, #FCC538 0%, #EDA311 100%);
    color: #333333;
  }
  &:active{opacity: .7}

  .count{
    position: absolute;
    right: -16px;
    top: -16px;
    z-index: 1;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #F04D23;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}