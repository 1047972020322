

.anamnez-card {
  background-color: #67B3E2;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 13px;
  position: relative;

  .btn-link{
    position: absolute;
    right: 28px;
    top: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: #074E7A;
    cursor: pointer;

    &:hover{text-decoration: underline;}
  }

  &.anamnez-cardSmall{padding: 0; margin-bottom: 5px}

  .anamnez-titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    &.anamnez-titleRowSmall{margin-bottom: 7px;}
  }
  .anamnez-cardText {
    color: #fff;
    font-size: 15px;
    line-height: 30px;

    &.anamnez-cardTextSmall{font-size: 12px; line-height: 16px}
  }
  .anamnez-cardTitle { 
    font-size: 18px;

    &.anamnez-cardTitleSmall{font-size: 14px; line-height: 20px}
  }
}
