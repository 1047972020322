.custom-scrollbar{
  .ScrollbarsCustom-TrackY{
    background: #fff!important;
    border-radius: 5px!important;
    top: 0!important;
    height: 100%!important;
  }
  .ScrollbarsCustom-ThumbY{
    background: #FBC437!important;
    border-radius: 5px!important;
  }
}