#RegisterPage {
    width: 736px;
    height: 100%;
    margin: 0 auto;
    
    .register-form{
      width: 100%;
      display: flex;  
      flex-wrap: wrap;    
      gap: 36px;

      .column{
        flex: 1
      }
    }
  }